import React from "react"
import PropTypes from "prop-types"


// var NotesTwo = React.createClass({

//   render: function() {
//       <div className="greeting">
//         Greeting: {this.props.greeting}
//       </div>
//   }

// )}

// class NotesTwo extends React.Component {
//   render () {
//     return (
//       <React.Fragment>
//         Greeting: {this.props.greeting}
//       </React.Fragment>
//     );
//   }
// }

class NotesTwo extends React.Component {
  render () {
    return (
      <div className="greeting">
        Greeting: {this.props.greeting}
      </div>
    );
  }
}

NotesTwo.propTypes = {
  greeting: PropTypes.string
};
export default NotesTwo
