import React from "react"
import PropTypes from "prop-types"
import If from '../If';

// export const Notes = 
class Notes extends React.Component {

  constructor(props) {    /* Note props is passed into the constructor in order to be used */
      super(props);
      this.state = {
        notes: this.props.notes, 
        showNewNoteForm: false
      };
      this.createNote = this.createNote.bind(this);
      this.deleteNote = this.deleteNote.bind(this);
      this.revealNewNoteForm = this.revealNewNoteForm.bind(this);
      this.render = this.render.bind(this);
  }

  createNote(noteContent) {
    var _this = this;
    var userId = this.props.userId; 
    var params = { user_id: userId, note_content: noteContent }

    $.ajax({
      type: 'POST', dataType: 'json', 
      url: '/notes', 
      data: params, 
      success: function(response) {
        // alert("createNote success response!");
        _this.setState({ notes: response.notes, showNewNoteForm: false })
      }
    });
  }

  deleteNote(noteId) {
    var _this = this;
    var userId = this.props.userId;
    var params = { user_id: userId, note_id: noteId, delete_note: true }
    var url = '/notes/' + noteId

    $.ajax({
      type: 'PUT', dataType: 'json', 
      url: url, 
      data: params, 
      success: function(response) {
        console.log("response.notes:", response.notes);
        _this.setState({ notes: response.notes, showNewNoteForm: false })
      }
    });
  }

  revealNewNoteForm(e) {
    e.preventDefault();
    this.setState({ showNewNoteForm: true })
  }

  render() {
    return (
      <div className="notes-container">
        <NoteForm newNote={true} 
                  showForm={this.state.showNewNoteForm} 
                  createNote={this.createNote} />
        <If test={ this.state.notes }>
          <NotesList notes={ this.state.notes }
                     deleteNote={this.deleteNote} />
        </If>
      </div>
      )
  }
}

// export const NotesList = 
class NotesList extends React.Component {

  constructor(props) {    /* Note props is passed into the constructor in order to be used */
    super(props);
    this.render = this.render.bind(this);
  }

  render() {

    var notes = [];
    var _this = this;

    this.props.notes.map(function(note, i) {
      notes.push(<Note key={note.id} 
                       deleteNote={_this.props.deleteNote}
                       note={note} />)
    })

    return (
      <div className="notes-list">
        {notes}
      </div>
      )
  }
}

// export const Note = 
class Note extends React.Component {

// WAIT!  why not have the update request happen here, within the note component?  
// having the add new note take place within the main container makes sense b/c it needs to re-render the whole list of
// notes to easily add the newest one to the stack of notes shown.
// but when it comes to updating, nothing outside of this note component needs adjusting, so why not just keep it all here?

  constructor(props) {    /* Note props is passed into the constructor in order to be used */
    super(props);
    this.state = {
      showEditForm: false, 
      note: this.props.note
    };
    this.editNote = this.editNote.bind(this);
    this.updateNote = this.updateNote.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.render = this.render.bind(this);
  }

  editNote(e) {
    e.preventDefault();
    this.setState({ showEditForm: true })
  }

  updateNote(noteId, noteContent) {
    console.log("noteId", noteId);
    console.log("noteContent", noteContent);
    
    var _this = this;
    var params = { note_id: noteId, note_content: noteContent }
    var url = '/notes/' + noteId

    $.ajax({
      type: 'PUT', dataType: 'json', 
      url: url, 
      data: params, 
      success: function(response) {
        // _this.setState({ notes: response.notes })
        _this.setState({ note: response.note, showEditForm: false })
      }
    });
  }

  handleDelete(e) {
    e.preventDefault();
    this.props.deleteNote(this.state.note.id)
  }

  render() {

    return (
      <div className="note">
        <header>
          <div className="note-menu">
            <h5 className="note-date">{this.state.note.posted_on}</h5>
            <small className="author">posted by {this.state.note.posted_by}</small>
            <div className="note-actions pull-right">
              <span className="note-action">
                <a href="#" className="action edit-action" title="Edit note"
                   onClick={this.editNote}>
                  <i className="ni ni-ruler-pencil"></i>&nbsp;&nbsp;</a>
              </span>
              <span className="note-action">
                <a href="#" className="action delete-action" title="Delete note"
                  onClick={this.handleDelete}>
                <i className="ni ni-fat-remove"></i></a>
              </span>
            </div>
          </div>
          <If test={this.props.showSubject}>
            <h6 className="subject-label">Posted to <a href="{this.state.note.link_to_note_subject}">{this.state.note.subject_email}</a></h6>
          </If>
        </header>
        <If test={!this.state.showEditForm}>
          <div className="note-content" dangerouslySetInnerHTML={ {__html: this.state.note.content}}>
          </div>
        </If>
        <If test={this.state.showEditForm}>
          <NoteForm key={this.state.note.id} 
                    note={this.state.note} 
                    updateNote={this.updateNote} 
                    newNote={false} />
        </If>
      </div>
    );

  }
}

// export const NoteForm = (apparently this didn't cause problems like it did when being used with the Notes component called in a view)
class NoteForm extends React.Component {

// LEFT OFF: need to update showForm state of note from parent, b/c that's where I know if a create note
// action is successful.  just updating the state in the parent doesn't trigger getInitialState again, so
// it's not automatically updated.  
// I found a SO question that's open that discusses this, probably several more thgat do as well.  

  constructor(props) {    /* Note props is passed into the constructor in order to be used */
    super(props);
    if (this.props.newNote) {
      // alert("in getInitialState for the new noteForm");
      this.state = { 
        showForm: this.props.showForm, 
        noteContent: '' 
      }
    } else { // editing exising note
      this.state = {
        showForm: true, 
        noteContent: this.props.note.content
      }
    }

    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.revealForm = this.revealForm.bind(this);
    this.render = this.render.bind(this);
  }

  handleFormChange(e) {
    this.setState({ noteContent: e.target.value })
  }

  handleSubmit(e) {
    e.preventDefault();
    // var noteId = e.target.getAttribute('data-note-id');
    // console.log("noteId", noteId);
    var noteContent = this.state.noteContent

    if (this.props.newNote) {
      this.props.createNote(noteContent)
    } else {
      this.props.updateNote(this.props.note.id, noteContent)
    }
  }

  revealForm(e) {
    e.preventDefault();
    this.setState({ showForm: true });
  }

  render() {
    // if (this.state.showForm) {
      return(
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <textarea className="note-content" 
                      rows="5" 
                      value={this.state.noteContent}
                      onChange={this.handleFormChange} />
          </div>
          <input type="submit" value="submit" className={"btn" + ' ' + 'btn-sm' + ' ' + 'btn-success'} />
        </form>
        )
    // } else {
    // }
  }
}

class NewNoteFormButton extends React.Component {

  constructor(props) {    /* Note props is passed into the constructor in order to be used */
    super(props);
    this.render = this.render.bind(this);
  }

  render() {

    return(
      <button className={"btn" + ' ' + 'btn-sm' + ' ' + 'btn-success'}
        name="button"
        type="button"
        onClick={this.props.revealNewNoteForm}>Add New Note</button>

      )
  }

}

// Notes.propTypes = {
//   greeting: PropTypes.string
// };
export default Notes




