
$(document).on('turbolinks:load', function() {

  $('#appt-cancel-link').on('click', function(e) {
    e.preventDefault();
    $('#appt-cancel-link').hide();
    $('#appt-cancel-confirm-links').show();
  });

  $('#appt-cancel-confirm-no').on('click', function(e) {
    e.preventDefault();
    $('#appt-cancel-confirm-links').hide();
    $('#appt-cancel-link').show();
  });

});
