// $(document).on('ready', function() {
$(document).on('turbolinks:load', function() {

  // console.log("users.js loaded");
  // console.log('Turbolinks.supported', Turbolinks.supported);

  $('#unsubscribe-link').on('click', function(e) {
    e.preventDefault();
    $('#unsubscribe-link').hide();
    $('#unsubscribe-confirm-links').show();
  });

  $('#unsubscribe-confirm-no').on('click', function(e) {
    e.preventDefault();
    $('#unsubscribe-confirm-links').hide();
    $('#unsubscribe-link').show();
  });

  $('tr[data-link]').click(function(e) {
    $('#payment-form-submit').prop('disabled', true);
    window.location = $(this).data('link');
  });

  // $('#payment-form-submit').on('click', function() {
  //   $(this).prop('disabled', true).val('Processing...');
  //   $('#payment-form').submit();
  // });
  // ^^ nice feature, but causes payment_nonce to be blank for some reason!
});
