import React from "react"
import PropTypes from "prop-types"
import If from '../If';
import Moment from 'moment';

class Tasks extends React.Component {

  constructor(props) {    /* Task props is passed into the constructor in order to be used */
      super(props);
      this.state = {
        tasks: this.props.tasks, 
        showNewTaskForm: false
      };
      this.createTask = this.createTask.bind(this);
      this.deleteTask = this.deleteTask.bind(this);
      this.revealNewTaskForm = this.revealNewTaskForm.bind(this);
      this.render = this.render.bind(this);
      this.taskFormElement = React.createRef();
  }

  createTask(taskContent, taskDueAt) {
    var _this = this;
    var userId = this.props.userId; 
    var params = { task_content: taskContent, task_due_at: taskDueAt }

    $.ajax({
      type: 'POST', dataType: 'json', 
      url: '/tasks', 
      data: params, 
      success: function(response) {
        _this.setState({ tasks: response.tasks, showNewTaskForm: false })
        _this.taskFormElement.current.resetForm()
      }
    });
  }

  deleteTask(taskId) {
    var _this = this;
    var userId = this.props.userId;
    var params = { user_id: userId, task_id: taskId, delete_task: true }
    var url = '/tasks/' + taskId

    $.ajax({
      type: 'PUT', dataType: 'json', 
      url: url, 
      data: params, 
      success: function(response) {
        console.log("response.tasks:", response.tasks);
        _this.setState({ tasks: response.tasks, showNewTaskForm: false })
      }
    });
  }

  revealNewTaskForm(e) {
    e.preventDefault();
    this.setState({ showNewTaskForm: true })
  }

  render() {
    console.log("this.taskFormElement.current", this.taskFormElement.current)
    return (
      <div className="tasks-container">
        <If test={ this.state.tasks }>
          <TasksList tasks={ this.state.tasks } 
                     updateTask={this.updateTask}
                     deleteTask={this.deleteTask} />
        </If>
        <hr />
        <TaskForm newTask={true} 
                  showForm={this.state.showNewTaskForm} 
                  createTask={this.createTask} 
                  ref={this.taskFormElement} />

      </div>
      )
  }
}

// export const TasksList = 
class TasksList extends React.Component {

  constructor(props) {    /* Task props is passed into the constructor in order to be used */
    super(props);
    this.render = this.render.bind(this);
  }

  render() {

    var tasks = [];
    var _this = this;

    this.props.tasks.map(function(task, i) {
      tasks.push(<Task key={task.id} 
                       task={task}
                       deleteTask={_this.props.deleteTask} />)
    })

    return (
      <div className="tasks-list table-responsive mt-5 mb-4">
        <table className="table align-items-center table-flush">
          <thead className="thead-light">
            <tr>
              <th scope="col" className="sort" data-sort="name">Task</th>
              <th scope="col">Due</th>
              <th scope="col" className="sort" data-sort="budget">Added</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody className="list">
            {tasks}
          </tbody>
        </table>
      </div>
      )
  }
}

// export const Task = 
class Task extends React.Component {

  constructor(props) {    /* Task props is passed into the constructor in order to be used */
    super(props);
    this.state = {
      showEditForm: false, 
      task: this.props.task
    };
    this.editTask = this.editTask.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.updateTask = this.updateTask.bind(this);
    this.completeTask = this.completeTask.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.render = this.render.bind(this);
  }

  editTask(e) {
    e.preventDefault();
    this.setState({ showEditForm: true })
  }

  cancelEdit(e) {
    e.preventDefault();
    this.setState({ showEditForm: false })
  }

  updateTask(taskId, taskContent, taskDueAt) {
    var _this = this;
    var params = { task_id: taskId, task_content: taskContent, task_due_at: taskDueAt }
    var url = '/tasks/' + taskId

    $.ajax({
      type: 'PUT', dataType: 'json', 
      url: url, 
      data: params, 
      success: function(response) {
        console.log("in updateTask function, success callback, response:", response);
        _this.setState({ task: response.task, showEditForm: false })
      }
    });
  }

  // deleteOrComplete(e) {
  //   var _this = this;

  //   if (e.currentTarget.classList.contains('complete-action')) {
  //     var action = 'complete';
  //   } else if (e.currentTarget.classList.contains('delete-action')) {
  //     var action = 'delete';
  //   }

  //   var taskId = this.state.task.id;
  //   var taskContent = this.state.task.content;
  //   var params = { action: action, task_id: taskId }
  //   var url = '/tasks/' + taskId

  //   $.ajax({
  //     type: 'PUT', dataType: 'json', 
  //     url: url, 
  //     data: params, 
  //     success: function(response) {
  //       _this.setState({ task: response.task, showEditForm: false })
  //     }
  //   });
  // }

  completeTask(e) {
    e.preventDefault();

    var _this = this;
    var taskId = this.state.task.id;
    var params = { task_id: taskId }
    var url = '/complete_task'

    $.ajax({
      type: 'PUT', dataType: 'json', 
      url: url, 
      data: params, 
      success: function(response) {
        // _this.setState({ tasks: response.tasks })
        _this.setState({ task: response.task })
      }
    });
  }

  handleDelete(e) {
    e.preventDefault();
    this.props.deleteTask(this.state.task.id)
  }

  render() {
    // var formatted_due_at = Moment(this.state.task.due_at).format('MMM Do YY, h:mm:ss a')
    var formatted_due_at = Moment(this.state.task.due_at).format('MMM Do, h:mm a')
    var formatted_added_at = Moment(this.state.task.added_at).format('MMM Do, h:mm a')

    if ( !this.state.showEditForm ) {

      return (
        <tr className={this.state.task.completed ? 'task task-completed' : 'task task-not-completed'}>
          <th scope="row">
            <div className="media align-items-center">
              <div className="media-body">
                <span className="task-content">{this.state.task.content}</span>
                <If test={this.props.showSubject}>
                  <h6 className="subject-label">Posted to <a href="{this.state.task.link_to_task_subject}">{this.state.task.subject_email}</a></h6>
                </If>
              </div>
            </div>
          </th>
          <td className="task-due-at budget">
            <span className="task-content">{formatted_due_at}</span>
          </td>
          <td className="task-added-at budget">
            <span className="task-added_at">{formatted_added_at}</span>
          </td>

          <td className="task-actions pull-right budget">
            <span className="task-complete">
              <a href="#" className="action complete-action" title="Complete task"
                 onClick={this.completeTask}>
                <i className="ni ni-check-bold"></i>&nbsp;&nbsp;</a>
            </span>
            <span className="task-edit">
              <a href="#" className="action edit-action" title="Edit task"
                 onClick={this.editTask}>
                <i className="ni ni-ruler-pencil"></i>&nbsp;&nbsp;</a>
            </span>
            <span className="task-delete">
              <a href="#" className="action delete-action" title="Delete task"
                 onClick={this.handleDelete}>
                <i className="ni ni-fat-remove"></i>&nbsp;&nbsp;</a>
            </span>
          </td>
        </tr>
      )

    } else {

      return (
        <tr className="task">
          <td colSpan="7">
          <TaskForm key={this.state.task.id} 
                    task={this.state.task} 
                    updateTask={this.updateTask} 
                    cancelEdit={this.cancelEdit} 
                    newTask={false} />
          </td>
        </tr>
      )
    }

  }
}

// export const TaskForm = (apparently this didn't cause problems like it did when being used with the Tasks component called in a view)
class TaskForm extends React.Component {

// LEFT OFF: need to update showForm state of task from parent, b/c that's where I know if a create task
// action is successful.  just updating the state in the parent doesn't trigger getInitialState again, so
// it's not automatically updated.  
// I found a SO question that's open that discusses this, probably several more thgat do as well.  

  constructor(props) {    /* task props is passed into the constructor in order to be used */
    super(props);
    if (this.props.newTask) {
      // alert("in getInitialState for the new taskForm");
      this.state = { 
        showForm: this.props.showForm, 
        taskContent: '', 
        taskDueAt: ''
      }
    } else { // editing exising task
      this.state = {
        showForm: true, 
        taskContent: this.props.task.content, 
        taskDueAt: this.props.task.due_at
      }
    }

    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.revealForm = this.revealForm.bind(this);
    this.hideForm = this.hideForm.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.render = this.render.bind(this);
  }

  handleFormChange(e) {
    if (e.target.id.indexOf('task_due_at') != -1) {
      this.setState({ taskDueAt: e.target.value })
    } else { // if (e.target.id.indexOf('task_due_at') != -1) {
      this.setState({ taskContent: e.target.value })
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    var taskContent = this.state.taskContent
    var taskDueAt = this.state.taskDueAt

    if (this.props.newTask) {
      this.props.createTask(taskContent, taskDueAt)
    } else {
      this.props.updateTask(this.props.task.id, taskContent, taskDueAt)
    }
  }

  revealForm(e) {
    e.preventDefault();
    this.setState({ showForm: true });
  }

  hideForm(e) {
    e.preventDefault();
    if (this.props.newTask) {
      this.setState({ showForm: false });
    } else {
      this.props.cancelEdit(e);
    }
  }

  resetForm() {
    this.setState({ taskContent: '', taskDueAt: '', showForm: false })
  }

  render() {
    if ( this.state.showForm ) {
      return(
        <form onSubmit={this.handleSubmit}>

          <div className="form-row d-flex">
            <div className="col-4">
              <div className="form-group">
                <textarea className="task-content" 
                          rows="4" 
                          value={this.state.taskContent}
                          onChange={this.handleFormChange} />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group datetime optional task_due_at">
                <label className="datetime optional" htmlFor="task_due_at">Due at</label>
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <input className="form-control mx-1 datetime optional" 
                         type="datetime-local" 
                         name="task[due_at]" 
                         id="task_due_at" 
                         value={this.state.taskDueAt}
                         onChange={this.handleFormChange} />
                </div>
              </div>
            </div>
            <div className="col-4 text-center justify-content-center align-self-center">
              <input type="submit" value="submit" className={"btn" + ' ' + 'btn-sm' + ' ' + 'btn-success'} />
              <button className={"btn" + ' ' + 'btn-sm' + ' ' + 'btn-warning'}
                name="button"
                type="button"
                onClick={this.hideForm}>Cancel</button>
            </div>
          </div>
        </form>
        )
      } else {
        return(
        <div>
          <button className={"btn" + ' ' + 'btn-sm' + ' ' + 'btn-outline-primary'}
                name="button"
                type="button"
                onClick={this.revealForm}>Add New Task</button>
          <a href="/tasks">
            <button className={"btn" + ' ' + 'btn-sm' + ' ' + 'btn-outline-primary' + ' ' + 'pull-right'}
                  name="button"
                  type="button">View All</button>
          </a>
        </div>
          )
      }
  }
}


// I THINK I CAN GET RID OF THIS...
class NewTaskFormButton extends React.Component {

  constructor(props) {    /* Task props is passed into the constructor in order to be used */
    super(props);
    this.render = this.render.bind(this);
  }

  render() {

    return(
      <button className={"btn" + ' ' + 'btn-sm' + ' ' + 'btn-success'}
        name="button"
        type="button"
        onClick={this.props.revealNewTaskForm}>Add New Task</button>

      )
  }

}

// Tasks.propTypes = {
//   greeting: PropTypes.string
// };
export default Tasks




