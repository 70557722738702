
$(document).on('turbolinks:load', function() {

  // using "feature detection", found here: https://stackoverflow.com/a/9851769/2599738
  // Firefox 1.0+
  var isFirefox = typeof InstallTrigger !== 'undefined';
  // Safari 3.0+ "[object HTMLElementConstructor]" 
  var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
  var isFirefoxOrSafari = (isFirefox || isSafari);

  console.log("isFirefox: ", isFirefox);
  console.log("isSafari: ", isSafari);
  console.log("isFirefoxOrSafari: ", isFirefoxOrSafari);

  if (!isFirefoxOrSafari) {
    console.log("is NOT FirefoxOrSafari");
    $('#firefox-safari-notice').hide();
  }


// $(function(){
   // $('.datepicker').datepicker({
   //    format: 'mm-dd-yyyy'
   //  });
// });

  // $('.btn').find('.open-text').hide();

//   $('.btn').on('click', function(){
//     console.log('button got clicked');
//     var $this = $(this);
//     var $collapsedText = $this.find('.collapsed-text');
//     var $openText = $this.find('.open-text');

// console.log('$collapsedText:', $collapsedText);
// console.log('$openText:', $openText);

//     if ( $this.hasClass('collapsed') ){
//       await sleep(1000);
//       console.log('this has collapsed class');
//       // $(this).html('+');
//       $collapsedText.show();
//       $openText.hide();

//     } else {
//       console.log('this DOES NOT has collapsed class');
//       // $(this).html('-');
//       $collapsedText.hide();
//       $openText.show();
//     }
//   });

});