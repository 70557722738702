import React from "react"
import PropTypes from "prop-types"

// var If = React.createClass({
class If extends React.Component {

  render () {
    if (this.props.test) {
      return <div>{this.props.children}</div>;
    }
    else {
      return false;
    }
  }
}

export default If